import { inject } from 'aurelia-dependency-injection';
import { Router, activationStrategy, RoutableComponentDetermineActivationStrategy } from 'aurelia-router';
import { ProjectBreadcrumbsService } from '../../../services/assets/ProjectBreadcrumbs';
import { ensureNumber } from '../../../util/numbers/ensureNumber';
import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { bindable } from 'aurelia-framework';

export type TAvailableTab = 'projects' | 'collect' | 'files';
export const availableTabs: TAvailableTab[] = ['projects', 'projects', 'collect', 'files'];

@inject(
    DatafiProAPI,
    Router,
    ActiveProgram,
    ProjectBreadcrumbsService,
)
export class ProjectTabs implements RoutableComponentDetermineActivationStrategy {

    tabIndexTop: TAvailableTab;
    @bindable projectId: number;
    surveyId: number;
    @bindable selectedIds: number[] = []
    constructor(
        private api: DatafiProAPI,
        private router: Router,
        private program: ActiveProgram,
        private breadcrumbs: ProjectBreadcrumbsService,
    ) { }

    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    async activate(params) {
        if (params?.data?.length) {
            this.selectedIds = params.data.map((d) => ensureNumber(d));
        }

        this.projectId = ensureNumber(params.projectId);
        this.surveyId = ensureNumber(params.surveyId);

        await this.projectIdChanged(this.projectId);
        this.program.load();

        if (this.availableTabs.includes(params.tabId)) {
            this.tabIndexTop = params.tabId;
            return;
        }
        this.tabIndexTop = this.availableTabs[0];

    }

    async projectIdChanged(id: number) {

        if (!id) {
            return;
        }
        const project = await this.api.projects.get(id, { query: { $modify: { toGeoJSON: [] } } });
        this.breadcrumbs.project = project;
    }

    get availableTabs() {
        const options = availableTabs.filter((tab) => {
            return typeof this.program.settings?.tabs?.[tab] !== 'undefined' ?
                this.program.settings.tabs[tab] :
                true;
        });
        return options;
    }

    changeTabTop(index: number) {
        const tabname = availableTabs[index];
        this.router.navigateToRoute(this.router.currentInstruction.config.name, { tabId: tabname, projectId: this.projectId });
        this.tabIndexTop = tabname;
    }

}
