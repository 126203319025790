import omit  from 'lodash.omit';
import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { AlertService } from '../../../services/util/Alert';
import { autoinject } from 'aurelia-framework';
import { Feature } from 'ol';
import { DialogController } from 'aurelia-dialog';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';

import { ValidatedProject } from '../../../types/Project';
import { Geometry } from 'ol/geom';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';

@autoinject
export class ProjectEditor {
    controller: ValidationController;
    object: ValidatedProject;
    saving: Promise<any>;

    constructor(
        private dialogController: DialogController,
        private controllerFactory: ValidationControllerFactory,
        private api: DatafiProAPI,
        private alerts: AlertService,
        private program: ActiveProgram,
    ) { }

    activate(projectOrProjectFeature: Feature<Geometry> | ValidatedProject) {
        const project: Record<string, any> = projectOrProjectFeature instanceof Feature ?
            projectOrProjectFeature.getProperties() :
            projectOrProjectFeature;
        this.object = new ValidatedProject(project);
        this.controller = this.controllerFactory.createForCurrentScope();

    }

    submit() {
        this.saving = this.controller.validate()
            .then((result) => {
                if (!result.valid) {
                    throw new Error('Form is not valid');
                }
                if (this.object.id) {
                    return this.api.projects.patch(this.object.id,
                        omit( this.object, ['geom'] ),
                    );
                }
                return this.api.projects.create(this.object);
            })
            .then((result) => {
                Object.assign(this.object, result);
                this.alerts.create({
                    label: 'Project updated',
                    level: 'success',
                    dismissable: true,
                });
                this.dialogController.ok(this.object);
            })
            .catch((e) => {
                this.alerts.create({
                    label: e.message,
                    level: 'error',
                    dismissable: true,
                });
            }).finally(() => this.saving = null);
        return this.saving;
    }

}
