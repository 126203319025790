import { Feature } from 'ol';
import { Style} from 'ol/style';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import get from 'lodash.get';
import { LogManager } from 'aurelia-framework';
import { IStyleFunction } from './styleTypes';
const logger = LogManager.getLogger('dfp:colorMap');

export interface ColorMapFunction extends IStyleFunction {
    colorMap: ColorMap;
    cache: Map<string|number, ValueStyle>;
}

export interface ValueStyle extends Style {
    value: string|number;
}
export interface ColorMap {
    field: string;
    styles?:  ValueStyle[];
}

const defaults = {
    stroke: new Stroke({ color: '#d4d3cf', width: 2 }),
    fill: new Fill({ color: '#d4d3cf35' }),
    image: new Circle({
        stroke: new Stroke({ color: '#d4d3cf', width: 2 }),
        fill: new Fill({ color: '#d4d3cf35' }),
        radius: 8,
    }),
};

export function createAttributeColorMap(map: ColorMap): ColorMapFunction {
    const cache = new Map();
    const styleFunction: ColorMapFunction = function style(feature: Feature<any>) {
        const value = get( feature.getProperties(), map.field) || 'default';

        if (!cache.has(value)) {
            let style = {};

            if (value) {
                style = Array.isArray(map.styles) ?
                    map.styles.find((style) => style.value == value) :
                map.styles?.[value];

                if(style){
                    logger.debug(`generting new style for ${map.field}: ${value}`, feature.getProperties(), style);
                } else {
                    logger.warn(`missing style for ${map.field}: ${value}`, map.styles);
                }
            }

            cache.set(value, style instanceof Style ? style : new Style({
                ...defaults,
                ...style,
            }));
        }

        return cache.get(value);
    };

    styleFunction.cache = cache;
    styleFunction.colorMap = map;
    styleFunction.dfpStyleType = 'attribute';

    return styleFunction;
}
