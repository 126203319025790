
import { ConsoleAppender } from 'aurelia-logging-console';
import { LogManager } from 'aurelia-framework';
import { SentryAppender } from 'aurelia-sentry';
import * as Sentry from '@sentry/browser';
import { AppConfig } from './services/util/AppConfig';

import {LogEvent, logger} from '@wsb_dev/dfp-xform-parser/lib/logging';

export function setupLogging(config: AppConfig){
    // configure logging
    const logLevels = {
        debug: 1,
        info: 1,
        warn: 1,
        error: 1,
    };

    const debug = window.localStorage.getItem('debug');
    let logLevel: string | number = window.localStorage.getItem('logLevel');
    if (logLevel && logLevels[logLevel]) {
        logLevel = LogManager.logLevel[logLevel];
    } else if (debug) {
        logLevel = LogManager.logLevel.debug;
    } else {
        logLevel = LogManager.logLevel.error;
    }
    LogManager.setLevel(logLevel);
    LogManager.addAppender(new ConsoleAppender());

    // set up sentry in non debug mode
    if (
        !/localhost/.test(window.location.hostname) &&
        logLevel !== LogManager.logLevel.debug
    ) {
        LogManager.addAppender(new SentryAppender());
        Sentry.init({
            dsn: config.SENTRY_DSN,
            release: `dfp-frontend@${config.SENTRY_RELEASE_VERSION}`,
            environment: config.SENTRY_ENVIRONMENT,
        });
    }

    // set up xform logging
    const xformLog = LogManager.getLogger('dfp:parser');
    logger.on('message', (data: LogEvent) => {
        xformLog[data.level](data.msg, data.data);
    });
}
