import { Field, Option, User } from '@wsb_dev/datafi-shared/lib/types';
import { IActionParams } from './IActionParams';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import parse from '@wsb_dev/dfp-xform-parser/lib/index';

export function getEditDFPForm(scope: IActionParams): Field[] {

    const projectOptions = scope.program.projectSchema.map((field) => {
        return {
            value: `project.metadata.${field.path}`,
            label: field.label,
        };
    });

    const options: Option[] = [
        {
            value: 'project.id',
            label: 'Project ID',
        },
        {
            value: 'project.status',
            label: 'Project Status',
        },
        {
            value: 'project.project_name',
            label: 'Project Name',
        },
        ...projectOptions,
        // todo add other scope fields, like user
        {
            value: 'program.id',
            label: 'Program ID',
        },
        {
            value: 'survey.id',
            label: 'Survey ID',
        },
        {
            value: 'auth.user.fullname',
            label: 'User Full Name',
        },
        {
            value: 'auth.user.email',
            label: 'User Email',
        },
        {
            value: 'auth.user.id',
            label: 'User ID',
        },
    ];

    const metadataField = scope.survey.surveySchema.find((f) => f.name === 'metadata');
    const actionFields: Field[] = flattenFields(metadataField.fields).map((field) => {
        return {
            name: `metadata.${field.path}`,
            label: field.label,
            type: 'select',
            options,
        };
    });

    return getFields([
        {
            name: 'programId',
            default: scope.program.id as any,
            label: 'Program ID',
        },
        {
            name: 'surveyId',
            default: parse( '' + scope.survey.id, 'surveyId').ast,
            label: 'Survey ID',
        },
        {
            name: 'actionFields',
            label: '(optional): Select fields to auto populate',
            // default: {},
            type: 'object',
            fields: actionFields,
        },
    ]);
}
