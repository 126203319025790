import { inject, bindable, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import factory from './factory/olMapFactory';
import Map from 'ol/Map';
import './ol-map.scss';
import ItemFactory from '../../services/util/ItemFactory';
import { debounced } from '../../util/decorators/debounced';
import {ResizeObserver} from 'resize-observer';

const log = LogManager.getLogger('dfp:map');
@inject(factory, EventAggregator)
export class OlMap {
    /**
     * Map options
     */
    @bindable() public options: Map;
    /**
     * Background color of map
     */
    @bindable() public background = '#fff';

    @bindable() public map: Map;

    private mapDiv: HTMLElement;
    private obs: ResizeObserver;

    constructor(private factory: ItemFactory, private eventAggregator: EventAggregator) {}

    async attached() {
        if (this.map) {
            return;
        }
        this.createMap();
    }

    detached() {
        if(this.obs){
            this.obs?.disconnect();
        }
        if (this.map) {
            log.info('removing map');
            this.map.setTarget(null);
            this.map.dispose();
            this.map = null;
        }
    }

    async optionsChanged() {
        if (this.map) {
            log.info('removing existing map');
            this.map.setTarget(null);
            this.createMap();
        }
    }

    @debounced(100) async createMap() {
        log.debug('creating new map', this.options);
        this.map = await this.factory.create({
            type: 'ol/Map',
            ...this.options,
            target: this.mapDiv,
        });

        this.obs = new ResizeObserver(() => {
            if (this.map) {
                this.map.updateSize();
            }
        });

        this.obs.observe(this.mapDiv);
        this.eventAggregator.publish('ol-map:created', this.map);
    }
}
