import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { IActionParams } from './IActionParams';

export function getGeneratePDF(scope: IActionParams): Field[] {
    return getFields([
        {
            label: 'Template Name',
            name: 'template',
        },
        {
            label: 'Include attachment urls (attachments will be given a "url" property)',
            type: 'select',
            name: 'attachments',
            options: [{
                value: true,
                label: 'Yes',
            }, {
                value: false,
                label: 'No',
            }],
        },
        {
            label: 'Output Format',
            name: 'format',
            type: 'select',
            options: [
                {value: 'pdf'},
                {value: 'docx'},
                {value: 'odt'},
                {value: 'xlsx'},
                {value: 'ods'},
            ],
        },
    ]);
}
