import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { exec, init } from 'pell';
import { debounced } from '../../util/decorators/debounced';
import { IDashboardItem, IDashboardTextConfig } from '../../types/Dashboards';
import { AlertService } from '../../services/util/Alert';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { purifyHTMLValueConverter } from '../../resources/value-converters/purify-html';
@autoinject
export class TextGadgetEditor {
    editor: any;
    textGadget: IDashboardItem<IDashboardTextConfig>;
    textColorPickerOpen = false;
    hyperlinkOpen = false;
    @observable currentTextColor = '#000';
    hyperlink: string;

    currentSelection: {node: Node, content: string, start: number, end: number};
    validationController: ValidationController;

    constructor(
        private ea: EventAggregator,
        private element: Element,
        private alerts: AlertService,
        private validationFactory: ValidationControllerFactory,
    ) { }

    activate(model) {

        if (!model.config) {
            model.config = {};
        }
        if (!model.config.data) {
            model.config.data = {};
        }

        this.textGadget = model;
    }

    attached() {
        this.initializeEditor();
    }

    initializeEditor() {
        this.editor = init({
            element: this.element.querySelector('.pell-wrapper'),
            onChange: (html: HTMLElement) => {
                this.setDashboardHTML(html);
            },
            styleWithCSS: false,
            actions: [
                'bold',
                'underline',
                {
                    name: 'olist',
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-123" viewBox="0 0 16 16"><path d="M2.873 11.297V4.142H1.699L0 5.379v1.137l1.64-1.18h.06v5.961h1.174Zm3.213-5.09v-.063c0-.618.44-1.169 1.196-1.169.676 0 1.174.44 1.174 1.106 0 .624-.42 1.101-.807 1.526L4.99 10.553v.744h4.78v-.99H6.643v-.069L8.41 8.252c.65-.724 1.237-1.332 1.237-2.27C9.646 4.849 8.723 4 7.308 4c-1.573 0-2.36 1.064-2.36 2.15v.057h1.138Zm6.559 1.883h.786c.823 0 1.374.481 1.379 1.179.01.707-.55 1.216-1.421 1.21-.77-.005-1.326-.419-1.379-.953h-1.095c.042 1.053.938 1.918 2.464 1.918 1.478 0 2.642-.839 2.62-2.144-.02-1.143-.922-1.651-1.551-1.714v-.063c.535-.09 1.347-.66 1.326-1.678-.026-1.053-.933-1.855-2.359-1.845-1.5.005-2.317.88-2.348 1.898h1.116c.032-.498.498-.944 1.206-.944.703 0 1.206.435 1.206 1.07.005.64-.504 1.106-1.2 1.106h-.75v.96Z"/></svg>',
                },
                {
                    name: 'ulist',
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/> </svg>',
                },
                {
                    name: 'italic',
                    result: () => exec('italic'),
                },
                {
                    name: 'Large Font Size',
                    icon: '<div style="font-size: 1.2rem">T</div>',
                    title: 'Large Font Size',
                    result: () => exec('fontSize', 10),
                },
                {
                    name: 'Medium Font Size',
                    icon: '<div style="font-size: 0.8rem">T</div>',
                    title: 'Medium Font Size',
                    result: () => exec('fontSize', 6),
                },
                {
                    name: 'Small Font Size',
                    icon: '<div style="font-size: 0.6rem">T</div>',
                    title: 'Small Font Size',
                    result: () => exec('fontSize', 3),
                },
                {
                    name: 'Justify Left',
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 15h14v2H3v-2zm0-5h18v2H3v-2zm0-5h14v2H3V9z"/></svg>',
                    title: 'Justify Left',
                    result: () => exec('justifyLeft'),
                },
                {
                    name: 'Justify Center',
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm2 15h14v2H5v-2zm-2-5h18v2H3v-2zm2-5h14v2H5V9z"/></svg>',
                    title: 'Justify Center',
                    result: () => exec('justifyCenter'),
                },
                {
                    name: 'Justify Right',
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm4 15h14v2H7v-2zm-4-5h18v2H3v-2zm4-5h14v2H7V9z"/></svg>',
                    title: 'Justify Right',
                    result: () => exec('justifyRight'),
                },
                {
                    name: 'Change Text Color',
                    icon: '<span style="color: red; font-size: 1rem;" class="material-icons">format_color_fill</span>',
                    title: 'Adjust Text Color',
                    result: () => this.textColorPickerOpen = true,
                },
                {
                    name: 'link',
                    result: () => this.openLinkEditor(),
                },
            ],
            classes: {
                actionbar: 'pell-actionbar',
                button: 'pell-button',
                content: 'pell-content',
                selected: 'pell-button-selected',
            },
        });

        this.setEditorHTML();
    }

    isValidURL(url) {
        try {
            url = new URL(url);
            return url.protocol === 'https:' || url.protocol === 'http:';
        } catch (err) {
            this.alerts.create({ label: `Invalid URL: ${url}`, level: 'warning', dismissable: true });
            return false;
        }
    }

    setEditorHTML() {
        this.editor.content.innerHTML = new purifyHTMLValueConverter().toView( this.textGadget.config.data.innerHTML);
    }

    // debounced so we're not constantly updating as we type
    @debounced(250)
    setDashboardHTML(html: HTMLElement) {
        this.textGadget.config.data.innerHTML = html;
        this.ea.publish('edit-item', this.textGadget);
    }

    submitColor() {
        exec('foreColor', this.currentTextColor);
        this.textColorPickerOpen = false;
    }

    setSelection(){
        const currentSelection = window.getSelection();
        this.currentSelection = {
            node: currentSelection.anchorNode,
            content: currentSelection.toString(),
            start: currentSelection.anchorOffset,
            end: currentSelection.focusOffset,
        };
    }

    restoreSelection(){
        const selection = window.getSelection();
        selection.removeAllRanges();
        const range = document.createRange();
        range.setStart(this.currentSelection.node, this.currentSelection.start);
        range.setEnd(this.currentSelection.node, this.currentSelection.end);
        selection.addRange(range);
    }

    openLinkEditor(){
        this.setSelection();
        this.hyperlinkOpen = true;
        this.validationController = this.validationFactory.createForCurrentScope();
    }

    submitLink(){
        this.validationController.validate().then((result) => {
            if(!result.valid){
                return;
            }
            this.hyperlinkOpen  = false;
            this.restoreSelection();
            exec('createLink', this.hyperlink);
            this.setDashboardHTML(this.editor.content.innerHTML);
            this.hyperlinkOpen = false;
            this.hyperlink = '';
            this.currentSelection = null;
        });
    }

}

ValidationRules
    .ensure('hyperlink').required().matches(/https:\/\/.+/).withMessage('Url must start with https://')
    .ensure('hyperlinkText').required()
    .on(TextGadgetEditor);
