import { Field } from '@wsb_dev/datafi-shared/lib/types';
import omit from 'lodash.omit';

export function updateFields(updatedFields: Field[], existingFields: Field[]): Field [] {

    if(!existingFields) return updatedFields;

    updatedFields.forEach((updatedField) => {
        const existingField = existingFields.find((other) => other.name === updatedField.name);
        if (existingField) {
            if(updatedField.fields){
                updatedField.fields = updateFields(updatedField.fields, existingField.fields);
            }
            Object.assign(updatedField, omit( existingField, [ // xls fields that will be overwritten anyways
                'fields',
                'default',
                'defaultAst',
                'calculation',
                'calculationAst',
                'relevant',
                'relevantAst',
            ]));
        }
    });

    return updatedFields;

}
