import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { DialogService } from 'aurelia-dialog';
import { autoinject, bindable } from 'aurelia-framework';
import { Params } from '@feathersjs/feathers';

import { DefaultActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTypes';
import { Filter } from '@wsb_dev/datafi-shared/lib/types/Filter';

import { ActionService } from '../../../services/actions/ActionService';

import { FilterDialog } from '../../../components/filter-dialog/filter-dialog';

import { ValidatedProject } from '../../../types/Project';

import { ensureNumber } from '../../../util/numbers/ensureNumber';
import { ProgramSurvey } from '../../../types/ProgramSurvey';
import { ProjectBreadcrumbsService } from '../../../services/assets/ProjectBreadcrumbs';
import { MapAdaptor, SelectItem, SelectResult } from '../../../services/util/MapAdaptor';
import { dashify } from '@wsb_dev/datafi-shared/lib/util/strings/dashify';
import { subscribe } from '../../../util/decorators/subscribe';
import { filtersToQuery } from '@wsb_dev/datafi-shared/lib/util/fields/filtersToQuery';
import { BaseModel } from '@wsb_dev/datafi-shared/lib/types/BaseModel';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import Layer from 'ol/layer/Layer';
import { Field, GenerateKoboURLData } from '@wsb_dev/datafi-shared/lib/types';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import { AlertService } from '../../../services/util/Alert';
import { FormSubmission } from '@wsb_dev/datafi-shared/lib/types/FormSubmission';
import { AppConfig } from '../../../services/util/AppConfig';
import { debounced } from '../../../util/decorators/debounced';
import { DFPUrlObj, IUrlParams } from '../../../components/ol-map/sources/dfpSourceUrlGeojson';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';

@subscribe({
    events: [
        { event: 'mapattached', eventEmitter: 'ma', fn: 'initializeMap' },
        { event: MapAdaptor.EVENT_SELECT, eventEmitter: 'ma', fn: 'handleSelectionChanged' },
        { event: MapAdaptor.EVENT_DESELECT, eventEmitter: 'ma', fn: 'handleSelectionChanged' },
        { event: 'patched', eventEmitter: 'api.formSubmissions', fn: 'onSurveyPatched' },
    ],
})
@autoinject
export class SurveyList {
    survey: ProgramSurvey;
    surveyId: number;

    projectId: number;
    project: ValidatedProject;

    params: Params;
    deleteParams: Params;

    idProp: string;
    surveyFilters: Filter[];

    @bindable selectedRows: BaseModel[] = [];
    selectedRowsChanged() {
        this.updateMapSelection();
    }
    rows: BaseModel[] = [];
    features: Record<string, any>[];
    surveyLayerId: string;
    fields: Field[];

    constructor(
        private dialogService: DialogService,
        private actionService: ActionService, // used in view
        private alerts: AlertService,
        private program: ActiveProgram,
        private breadcrumbs: ProjectBreadcrumbsService,
        private ma: MapAdaptor,
        private api: DatafiProAPI,
        private config: AppConfig,
    ) { }

    async activate(result): Promise<void> {
        this.surveyId = ensureNumber(result.surveyId);
        this.projectId = ensureNumber(result.projectId);
        this.surveyLayerId = dashify(`surveys-${this.surveyId}`);

        if (result.create) {
            const targetFields = {};
            Object.keys(result).forEach((key) => {
                targetFields[key] = key;
            });
            const data: GenerateKoboURLData = {
                target: [{ ...result }],
                id: 'GenerateKoboURL',
                surveyId: result.surveyId,
                urlType: 'offline_url',
                targetFields,
                tab: true,
            };
            this.actionService.create(data);
        }

        return this.program.load().then(async () => {
            const survey = this.program.surveys.find((survey) => survey.id === this.surveyId);
            if (!survey) {
                throw new Error('Survey was not found');
            }

            this.survey = new ProgramSurvey(survey);
            this.breadcrumbs.survey = this.survey;

            if (this.projectId) {
                this.api.projects.get(this.projectId).then((proj) => {
                    this.project = proj;
                    this.breadcrumbs.project = this.project;
                });
            }
            this.idProp = 'id';
            this.deleteParams = {};

            // calculate fields
            this.fields = flattenFields(survey.surveySchema, null, { arrays: true });

            this.params = {
                ...this.getDefaultParams(),
                ...this.params,
            };

            this.surveyFilters = this.fields
                .filter((f) => f.visibility?.filter);

            await this.initializeMap();
            await this.updateMapData();
        });
    }

    getDefaultParams() {
        return {
            $modify: { toGeoJSON: [] },
            survey_id: this.surveyId,
            project_id: this.projectId || undefined,
            program_id: this.program.id,
        };
    }

    async onSurveyPatched(row: FormSubmission) {
        if (this.survey.id !== row.survey_id) {
            return;
        }

        const layerId = `surveys-${this.survey.id}`;
        row = await this.api.formSubmissions.get(row.id, { query: { $modify: 'toGeoJSON' } });
        this.ma.updateLayer({
            update: [row],
            layerId,
        });
        for (let i = 0; i < this.rows.length; i++) {
            const existing = this.rows[i];
            if (existing.id === row.id) {
                this.rows.splice(i, 1, row);
            }
        }
    }

    async initializeMap() {
        const layer = await this.ma.getVectorLayer({
            layerId: this.surveyLayerId,
            createLayer: true,
            updateLayer: true,
            layerOptions: {
                dfpReady: true,
                visible: true,
                source: {
                    type: 'dfp/source/VectorDFP',
                    url: {
                        type: 'url/dfp/GeoJSON',
                        serviceType: 'form-submissions',
                        baseUrl: this.config.API_HOST,
                        programId: this.program.id,
                        typeId: this.survey.id,
                    } as IUrlParams,
                },
            },
        });

        (layer.getSource().getUrl() as DFPUrlObj)?.setParams({ query: this.getQuery(layer) }, layer);
        layer.setVisible(true);

    }

    @debounced(200)
    async updateMapData() {
        if (!this.ma.map) {
            return;
        }

        this.features = [];
        const layer = await this.ma.getVectorLayer({ layerId: this.surveyLayerId });
        const query = this.getQuery(layer);
        let source = layer?.getSource() as VectorSource | Cluster;
        if (source instanceof Cluster) {
            source = source.getSource() as VectorSource;
        }

        if (source) {
            const url = source.getUrl() as DFPUrlObj;
            url.setParams({ query: query }, layer);
        }

    }

    getQuery(layer: Layer) {
        const query = ((layer?.getSource() as VectorSource)?.getUrl() as DFPUrlObj)?.params.query || layer?.get('query') || {};
        return {
            ...query,
            ...this.params,
            program_id: this.program.id,
            $limit: 5000,
        };
    }

    updateFilters() {
        return this.dialogService.open({
            viewModel: FilterDialog,
            model: {
                filterProperties: this.surveyFilters,
            },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            const query = filtersToQuery(result.output);
            this.surveyFilters = result.output;

            this.params = {
                ...this.params,
                ...query,
                $modify: { toGeoJSON: [], ...query.$modify },
            };
            this.updateMapData();
        });
    }

    updateMapSelection() {
        let result = [];

        // If Map selected first and selectedRows exist
        if (this.selectedRows.length && this.features?.length) { // If Map selected first and selectedRows exist
            this.selectedRows.forEach((row2) => {
                // Table rows have been selected (greater than map selection count)
                if ((this.selectedRows.length > this.features?.length) || !this.features) {
                    result.push(row2);
                } else {
                    // Map has been selected (greater than row selection count)
                    this.features.forEach((feature) => {
                        if (feature.id === row2.id) {
                            result.push(feature);
                        }
                    });
                }
            });
        }

        // If Table rows selected first
        if (this.selectedRows.length && !this.features?.length) {
            result = this.selectedRows;
        }

        if (result.length) {
            return this.ma.select(result, this.surveyLayerId, 'id');
        }

        if (!this.selectedRows.length) {
            return this.ma.clearSelection();
        }
    }

    handleSelectionChanged(event: SelectResult): void | Promise<void> {
        const layerId = `surveys-${this.surveyId}`;
        const features = event.target
            .filter((i) => i.layerId === layerId);

        if (event.type === 'deselect') {
            return this.deselectRows(features);
        }
        return this.selectRows(features);
    }

    deselectRows(features: SelectItem[]): void {
        if (features[0]?.properties?.features) { // cluster deselect
            features = this.clusterSelection(features);
        }
        const ids = features.map((i) => i.properties.id);
        this.selectedRows = this.selectedRows.filter((row) => ids.indexOf(row.id) < 0);
    }

    clusterSelection(features) {
        const clusterSelection = [];
        features.forEach((feature: SelectItem) => {
            feature.properties.features.forEach((att: VectorSource) => {
                clusterSelection.push({
                    properties: {
                        id: att.getProperties().id,
                    },
                });
            });
        });
        return clusterSelection;
    }

    async selectRows(features: SelectItem[]): Promise<void> {
        if (features[0]?.properties?.features) { // cluster selection
            this.features = this.clusterSelection(features);
        } else {
            this.features = features.map((f) => f.properties);
        }

        const promises = this.features.map((i) => {
            return this.api.formSubmissions.get(i.properties?.id ? i.properties.id : i.id)
                .catch((e) => {
                    this.alerts.create({label: e.message, level: 'warning', dismissable: true});
                }) as Promise<FormSubmission>;
        });

        const rows = await Promise.all(promises);
        let newRows = 0;

        rows.forEach((newRow) => {
            if (!newRow) {
                return;
            }
            this.selectedRows = [
                newRow,
                ...this.selectedRows,
            ];
            const existing = this.rows.find((row) => {
                const id = row.id;
                const newId = newRow.id;
                return newId === id;
            });
            if (existing) {
                return;
            }
            this.rows.unshift(newRow);
            newRows++;
        });
        if (newRows) {
            this.alerts.create({ label: `${newRows} ${newRows > 1 ? 'rows' : 'row'} added to the table from map selection`, dismissable: true });
        }
    }

    createAction(action: DefaultActionData) {
        this.actionService.create({
            ...action,
            target: [this.project],
        });
    }
}
