import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { IActionParams } from './IActionParams';

export function getPopulate(scope: IActionParams): Field[] {
    const populateFields = getFields([
        {
            name: 'nameAs',
            label: 'Property to populate as',
        },
        {
            name: 'service',
            label: 'Service to query data from',
        },
        {
            name: 'serviceMethod',
            label: 'Query method',
            type: 'select',
            options: [
                {value: 'find', label: 'Find many (uses primary and foreign key)'},
                {value: 'get', label: 'Get one by id (uses primary key only)'},
            ],
        },
        {
            name: 'parentField',
            label: 'Primary key field - field from parent record',
        },
        {
            name: 'childField',
            label: 'Foreign key field - field from service to query',
        },
    ]);

    const arrayField = getFields([
        {
            name: 'populate',
            type: 'array',
            label: 'Additional properties to populate on each record',
        },
    ]);

    arrayField[0].fields = populateFields;

    return arrayField;
}
