import { autoinject, bindable } from 'aurelia-framework';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { EventAggregator } from 'aurelia-event-aggregator';
import { IDashboardItem, IDashboardSummaryConfig } from '../../types/Dashboards';
@autoinject
export class SummaryGadgetEditor {
    @bindable editItem: IDashboardItem<IDashboardSummaryConfig>;
    @bindable storeData: SimpleStore;
    statisticOptions = ['Count', 'Average', 'Minimum', 'Maximum', 'Sum'];

    constructor(
        private stores: StoreManager,
        private ea: EventAggregator,
    ) { }

    activate(model: any): void {
        this.editItem = model;
        this.getStoreData();
    }

    updateData(event?) {
        if (event === 'store') {
            this.getStoreData();
        }
        this.ea.publish('edit-item', this.editItem);
    }

    getStoreData() {

        this.storeData = this.stores.getStore(this.editItem.config.storeId) as SimpleStore;

        this.storeData?.refresh()
            .then((result) => {
                this.storeData = result;
            });
    }

}
