import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { ProjectBreadcrumbsService } from '../../../services/assets/ProjectBreadcrumbs';
import { subscribe } from '../../../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../../../util/events/eventAggregatorWrapper';

export type RouteLevels = 'list' | 'project-detail' | 'project-survey' | 'survey' | 'assets-table' | 'assets';

@autoinject
@subscribe({
    events: [
        {eventEmitter: 'ea', event: 'router:navigation:complete', fn: 'onRouteComplete'},
    ],
})
export class ProjectBreadcrumbs {
    constructor(
        private breadcrumbs: ProjectBreadcrumbsService,
        private router: Router,
        private ea: EventAggregatorWrapper,
    ) {}

    onRouteComplete(){
        this.validateRoute(this.router.currentInstruction.config.name as RouteLevels);
    }

    validateRoute(route: RouteLevels){
        if(route === 'list' || route === 'project-detail' || route === 'assets'){
            this.breadcrumbs.survey = null;
            this.breadcrumbs.assetType = null;
        }

        if(route === 'list'){
            this.breadcrumbs.project = null;
        }
    }

    navigate(route: RouteLevels){
        this.validateRoute(route);
        this.router.navigateToRoute(route, {
            surveyId: this.breadcrumbs.survey?.id,
            projectId: this.breadcrumbs.project?.id,
        });
    }
}
