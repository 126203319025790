import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { IActionParams } from './IActionParams';

export function getQuery(scope: IActionParams) {
    return getFields([
        {
            name: 'service', type: 'select', options: [
                { label: 'Form Submissions', value: 'api/v1/form-submissions' },
                { label: 'Projects', value: 'api/v1/projects' },
                { label: 'Project Files', value: 'api/v1/project-photos' },
            ] },
        {
            name: 'defaultQuery', label: 'Query', type: 'object', fields: [
                { name: 'survey_id' },
                { name: 'program_id' },
                { name: '$limit', label: 'Query size (max 500). Tip: Fetch data quicker with a higher query size.' },
            ],
        },
    ]);
}
