import get from 'lodash.get';

export function calculateStats(storeData, fieldSelection, statSelection) {
    let calculation: number;
    const data = keyValSearch(storeData, fieldSelection);

    if (data.length) {
        calculation = data.reduce((total, current) => {
            switch (statSelection) {
            case 'Sum':
                if (current) {
                    return Number(total) + Number(current);
                }
                break;
            case 'Average':
                if (current) {
                    return Math.round((total + current / data.length) * 10) / 10;
                }
                break;
            case 'Minimum':
                if (current) {
                    return Math.min(...data);
                }
                break;
            case 'Maximum':
                if (current) {
                    return Math.max(...data);
                }
                break;
            case 'Count':
                return data.length;
            default:
                return '';
            }
        }, 0);
        if (!isNaN(calculation)) {
            return calculation;
        }
    } else {
        return calculation = undefined;
    }
}

export function keyValSearch(data: Record<string, any>[], key: string, results: Array<any> = []): Array<any>{
    const res = results;
    Object.keys(data).forEach((obj) => {
        const val = get(data[obj], key);
        if (val) {
            res.push(val);
        }
    });
    return res;
}
