import { ActiveProgram } from '../../../services/util/ActiveProgram';
import omit from 'lodash.omit';
import { ValidationControllerFactory, ValidationController } from 'aurelia-validation';
import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-dependency-injection';

import { AlertService } from '../../../services/util/Alert';

import { ValidatedProjectFile } from '../../../types/ProjectFile';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { LogManager, bindable } from 'aurelia-framework';
import { FileFolder } from '@wsb_dev/datafi-shared/lib/types/FileFolder';

const log = LogManager.getLogger('dfp:project-file');

export interface IEditActivateParams{
    files: ValidatedProjectFile[];
    folders: FileFolder[];
}

@autoinject
export class ProjectFileEdit {
    @bindable multipleFile: boolean;
    @bindable disabled = true;

    file: ValidatedProjectFile;
    files: ValidatedProjectFile[];
    folders: FileFolder[];
    destinationFolder: string;
    validation: ValidationController;
    tags: string[];
    newTag: string;

    constructor(
        private dialog: DialogController,
        private validationFactory: ValidationControllerFactory,
        private alerts: AlertService,
        private program: ActiveProgram,
        private api: DatafiProAPI,
    ) { }

    activate(model : IEditActivateParams) {
        this.files = model.files;
        this.folders = model.folders;

        if (this.files.length > 1) {
            this.multipleFile = true;
            this.file = new ValidatedProjectFile({}, this.api.files);
            const tagSet = this.files.reduce((prev: Set<string>, current: ValidatedProjectFile) => {
                const tags = current.user_tags?.split(',') || [];
                tags.forEach((tag) => prev.add(tag));
                return prev;
            }, new Set());
            this.tags = Array.from(tagSet);
            this.validation = this.validationFactory.createForCurrentScope();
        } else {
            this.file = new ValidatedProjectFile(this.files[0], this.api.files);
            this.tags = this.file.user_tags?.split(',') || [];
            this.validation = this.validationFactory.createForCurrentScope();

            if (!this.file._blobs.full) {
                this.file.getFull();
            }
        }
    }

    submit() {
        return this.validation.validate()
            .then((result) => {

                if (!result.valid) {
                    throw new Error('Form is not valid');
                }

                this.file.user_tags = this.tags?.join(',');
                if(this.destinationFolder){
                    this.file.program_folder = this.destinationFolder;
                }

                return Promise.all(this.files.map((file) => {
                    return this.api.projectFiles.patch(file.id, this.file);
                }));
            })
            .then((result: ValidatedProjectFile[]) => {
                for (let i = 0; i < result.length; i++) {
                    Object.assign(this.files[i], omit(result[i], ['geom']));
                }

                this.alerts.create({
                    label: this.files.length > 1 ? 'Files Saved' : 'File Saved',
                    level: 'success',
                    dismissable: true,
                });
                this.dialog.ok(this.files);
            })
            .catch((e) => {
                log.warn(e);
                this.alerts.create({
                    label: e,
                    level: 'error',
                    dismissable: true,
                });
            });
    }

    deleteFile() {
        return this.api.projectFiles.remove(this.file.id)
            .then(() => {
                this.alerts.create({
                    label: 'File Removed',
                    level: 'success',
                    dismissable: true,
                });
                this.dialog.ok();
            })
            .catch((e) => {
                log.warn(e);
                this.alerts.create({
                    label: e,
                    level: 'error',
                    dismissable: true,
                });
            });
    }
}
