import { ILayerConfigMeta } from 'components/ol-map-config/ol-map-config';

export const defaultStyle = {
    type: 'ol/style/Style',
    stroke: {
        type: 'ol/style/Stroke',
        color: 'rgb(250, 193, 21)',
        width: 2,
    },
    fill: {
        type: 'ol/style/Fill',
        color: 'rgba(250, 193, 21, 0.2)',
    },
    image: {
        type: 'ol/style/Circle',
        fill: {
            type: 'ol/style/Fill',
            color: 'rgba(250, 193, 21, 0.8)',
        },
        stroke: {
            type: 'ol/style/Stroke',
            color: '#fff',
        },
        radius: 8,
    },
};

export const defaultMap = {
    view: {
        type: 'ol/View',
        zoom: 10,
        center: [-10371953.250177655, 5632594.2196689015],
    },
    layers: [
        {
            id: 'OpenStreetMapDefault',
            label: 'OSM Default',
            type: 'ol/layer/Tile',
            source: {
                type: 'ol/source/OSM',
            },
        },
    ],
};

export const defaultLayerObjects: Record<string, ILayerConfigMeta> = {
    addressFeatures: { config: { id: 'addressFeatures', visible: true, label: 'Address Features', geometryType: 'point' } },
    gpsFeatures: { config: { id: 'gpsFeatures', visible: true, label: 'GPS Features', geometryType: 'point' } },
    userFeatures: { config: { id: 'userFeatures', visible: true, label: 'User Features', geometryType: 'point' } },
    mapboxGray: {
        config: {
            type: 'ol/layer/Tile',
            source: {
                type: 'ol/source/XYZ',
                url: 'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token={{ globals.MAPBOX_TOKEN }}',
                tileSize: 512,
                attributions: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/" target="_blank"><strong>Improve this map</strong></a>',
            },
            label: 'Mapbox Gray',
            id: 'mapbox-gray',
            thumbnailUrl: '/basemap-map.png',
            visible: true,
        },
    },
    mapboxAerial: {
        config: {
            type: 'ol/layer/Tile',
            source: {
                type: 'ol/source/XYZ',
                url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/{z}/{x}/{y}?access_token={{ globals.MAPBOX_TOKEN }}',
                tileSize: 512,
                attributions: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/" target="_blank"><strong>Improve this map</strong></a>',
            },
            id: 'mapbox-aerial',
            label: 'Mapbox Aerial',
            thumbnailUrl: '/basemap-aerial.png',
            visible: false,
        },
    },
    OSM: {
        config: {
            id: 'OpenStreetMapDefault',
            label: 'OSM Default',
            visible: true,
            type: 'ol/layer/Tile',
            source: {
                type: 'ol/source/OSM',
            },
        },
    },
};
