import { FileFolder } from '@wsb_dev/datafi-shared/lib/types/FileFolder';
import { bindable } from 'aurelia-framework';

export class FolderEditList {
   @bindable folders: FileFolder[] = [];

   addFolder() {
       this.folders = [
           ...this.folders,
            {} as FileFolder,
       ];
   }

   removeFolder(index: number){
       this.folders.splice(index, 1);
   }

}
