import Style from 'ol/style/Style';
import { Circle as CircleStyle, Fill, Stroke, Text } from 'ol/style';
import defaults from 'lodash.defaults';
import { IStyleFunction, TStyleFunctionType } from './styleTypes';

export interface ClusterStyleOptions {
    /**
     * Flll color
     */
    color?: string;
    outlineColor?: string | number[];
    outlineWidth?: number;

}

function clusterStyle(size, options: ClusterStyleOptions) {
    return new Style({
        image: new CircleStyle({
            radius: 14,
            stroke: new Stroke({
                color: options.outlineColor,
                width: options.outlineWidth,
            }),
            fill: new Fill({
                color: options.color,
            }),
        }),
        text: new Text({
            text: size.toString(),
            font: 'bold 14px sans-serif',
            fill: new Fill({
                color: options.outlineColor,
            }),
        }),
    });

}

function nonClusterStyle(options: ClusterStyleOptions) {
    return new Style({
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({ color: options.color }),
            stroke: new Stroke({
                color: options.outlineColor, width: options.outlineWidth,
            }),
        }),
    });
}

export interface ClusterFunction extends IStyleFunction {
    options: ClusterStyleOptions;
    cache: Map<number, Style>;
}
export function createClusterStyle(options?: ClusterStyleOptions): ClusterFunction {

    defaults(options, {
        color: '#faaf3f',
        outlineColor: [255, 255, 255],
        outlineWidth: 2,
    } as ClusterStyleOptions);

    const styleCache = new Map();

    const styleFunction: ClusterFunction = function (feature) {
        const size = feature.get('features')?.length || 1;

        const cacheKey = size >= 2 ? `cluster-${size}` : 'thumb';
        if (!styleCache[cacheKey]) {
            styleCache[cacheKey] = size >= 2 ? clusterStyle(size, options) : nonClusterStyle(options);
        }
        return styleCache[cacheKey];
    };
    styleFunction.options = options;
    styleFunction.dfpStyleType = 'cluster';
    styleFunction.cache = styleCache;
    return styleFunction;
}
