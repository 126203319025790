import { DialogService } from 'aurelia-dialog';
import { bindable, PLATFORM, autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { ProjectEditor } from '../project-edit/project-edit';
import { MDCDelete } from '../../mdc-delete-dialog/mdc-delete-dialog';

import { AlertService } from '../../../services/util/Alert';
import { ActiveProgram } from '../../../services/util/ActiveProgram';

import { ValidatedProject } from '../../../types/Project';

import { subscribe } from '../../../util/decorators/subscribe';
import { MapAdaptor, SelectResult } from '../../../services/util/MapAdaptor';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { Polygon, Point } from 'geojson';
import centroid from '@turf/centroid';
import { Project } from '@wsb_dev/datafi-shared/lib/types';

PLATFORM.moduleName('../../mdc-delete-dialog/mdc-delete-dialog');
PLATFORM.moduleName('../project-edit/project-edit');

const log = LogManager.getLogger('dfp:projects');

@subscribe({
    events: [
        { eventEmitter: 'api.projects', event: 'patched', fn: 'projectPatched' },
        { eventEmitter: 'ma', event: 'select', fn: 'updateSelectedProject' },
        { eventEmitter: 'ma', event: 'deselect', fn: 'removeSelection' },
    ],
})
@autoinject
export class ProjectDetail {
    @bindable projectId: number;
    @bindable project: ValidatedProject;
    projectCentroid: Point;
    selectedIds = [];

    constructor(
        private api: DatafiProAPI,
        private dialogService: DialogService,
        private alerts: AlertService,
        private program: ActiveProgram,
        private router: Router,
        private el: Element,
        private ma: MapAdaptor,
    ) { }

    activate() {
        this.selectedIds = [];
    }

    detached() {
        if (this.ma.isEditing) {
            this.ma.cancel();
        }
    }

    // use arrow syntax for binding
    projectPatched = (proj: Project): void => {
        if (proj.id === this.project.id) {
            this.project = {
                ...proj,
            };
        }
    }

    async projectChanged(project: ValidatedProject): Promise<void> {
        if (!project) {
            return;
        }

        if (typeof project.geom === 'string') {
            // Need to re-fetch project geometry after edits because of known server side issue
            this.api.projects.get(project.id, { query: { $modify: { toGeoJSON: [] } } })
                .then((proj) => {
                    this.projectCentroid = proj.geom ? centroid(proj.geom as Polygon).geometry : null;
                });
        } else {
            this.projectCentroid = project.geom ? centroid(project.geom as Polygon).geometry : null;
        }
        const feature = await this.ma.getFeature(project, 'projectFeatures');
        if (!feature) { // If no feature in map extent, try creating it temporarily so it exists in map
            this.api.projects.get(project.id, { query: { $modify: 'toGeoJSON' } })
                .then((proj: ValidatedProject) => {
                    const tempFeature = this.ma.createFeatures([proj], 'geom');
                    this.ma.zoom(tempFeature[0]);
                    setTimeout(() => {this.ma.select([project], 'projectFeatures');}, 2000);
                });
        } else {
            this.ma.zoom(feature);
        }
    }

    editProject(project) {
        return this.dialogService.open({
            viewModel: ProjectEditor,
            model: new ValidatedProject(
                JSON.parse(JSON.stringify(project)),
            ),
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            Object.assign(this.project, result.output);
        });
    }

    deleteProject(project) {
        return this.dialogService.open({
            viewModel: MDCDelete,
            model: { ...project },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            this.api.projects.remove(project.id)
                .then(() => {
                    this.alerts.create({
                        label: 'Project removed',
                        dismissable: true,
                    });
                    this.router.navigateToRoute('projects', { programId: this.program.id });
                })
                .catch((e) => {
                    this.alerts.create({
                        label: e.data.table === 'form_submissions' ? 'Form data exists on this project, cannot delete' : e.message,
                        level: 'error',
                        dismissable: true,
                    });
                });
        });
    }

    updateSelectedProject(event: SelectResult) {
        // filter projects by desired layer
        const projectItems = event.target.filter((item) => item.layerId === 'projectFeatures');
        // get their ids
        this.selectedIds.push(...projectItems.map((item) => item.properties.id));
        // if we have an id, get the project
        if (projectItems.length && this.selectedIds.length == 1) {
            this.api.projects.get(projectItems[0].properties.id).then((res) => {
                if (res) {
                    // set the result as the current project
                    this.project = res;
                    // update routing
                    this.router.navigateToRoute('project-detail', { projectId: this.project.id });
                }
            });

            // otherwise redirect users back to the project list with the currently selected ids
        } else {
            this.router.navigateToRoute('list', { tabId: 0, data: this.selectedIds !== undefined ? this.selectedIds : [] });
            this.selectedIds = [];
        }
        return;
    }

    removeSelection(event: SelectResult) {
        // get projects for the target layer
        const projectItems = event.target.filter((item) => item.layerId === 'projectFeatures');

        // nothing? return
        if (!projectItems.length || !projectItems) {
            return;
        }

        // get ids
        const ids = projectItems.map(({ properties: project }) => project.id);

        // remove as needed
        this.selectedIds = this.selectedIds.filter((id) => {
            return !ids.includes(id);
        });
        return;
    }
}
