import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-dependency-injection';
import { Program } from '../../../types/Program';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ProgramSurvey } from '../../../types/ProgramSurvey';
import { Paginated } from '@feathersjs/feathers';
import { FormSubmission } from '@wsb_dev/datafi-shared/lib/types/FormSubmission';
import { ProgramAsset, ProgramAssetType, ProgramConfig, Project } from '@wsb_dev/datafi-shared/lib/types';

@autoinject
export class ProgramDelete {
    program: Program;
    surveys: number;
    forms: number;
    projects: number;
    configs: number;
    assetTypes: number;
    assets: number

    constructor(
        private dialog: DialogController,
        private api: DatafiProAPI,
    ) {}

    async activate(model: Program) {
        this.program = model;
        this.surveys = await this.api.programSurveys.find({
            query: {program_id: this.program.id, $limit: 1, $select: ['id']},
        }).then((res) => (res as Paginated<ProgramSurvey>).total);
        this.forms = await this.api.formSubmissions.find({
            query: {program_id: this.program.id, $limit: 1, $select: ['id']},
        }).then((res) => (res as Paginated<FormSubmission>).total);
        this.projects = await this.api.projects.find({
            query: {program_id: this.program.id, $limit: 1, $select: ['id']},
        }).then((res) => (res as Paginated<Project>).total);
        this.configs = await this.api.programConfigs.find({
            query: {program_id: this.program.id, $limit: 1, $select: ['id']},
        }).then((res) => (res as Paginated<ProgramConfig>).total);
        this.assetTypes = await this.api.programAssetTypes.find({
            query: {program_id: this.program.id, $limit: 1, $select: ['id']},
        }).then((res) => (res as Paginated<ProgramAssetType>).total);
        this.assets = await this.api.programAssets.find({
            query: {program_id: this.program.id, $limit: 1, $select: ['id']},
        }).then((res) => (res as Paginated<ProgramAsset>).total);
    }
}
