import { Field, Option } from '@wsb_dev/datafi-shared/lib/types';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { LogManager, bindable } from 'aurelia-framework';
import parse from '@wsb_dev/dfp-xform-parser/lib/index';

const log = LogManager.getLogger('field-edit');

export class FieldEdit {
    @bindable field: Field;
    fieldSchema = getFields([
        { name: 'label', label: 'Display Name' },
        { name: 'name', label: 'Database Name - use only letters, numbers, and underscores' },
        {
            name: 'type', type: 'select', options: [
                { value: 'text' }, { value: 'number' }, { value: 'date' }, { value: 'email' }, { value: 'password' },
                { value: 'tel' }, { value: 'time' }, { value: 'url' }, { value: 'week' }, { value: 'month' }, { value: 'string' },
                { value: 'select' }, { value: 'checkbox' }, { value: 'radio' }, { value: 'date' }, { value: 'array' }, { value: 'object' },
                { value: 'geometry' }, {value: 'image'},
            ],
        },
        { name: 'defaultSort', type: 'select', options: [{ value: -1, label: 'Descending' }, { value: 1, label: 'Ascending' }, { value: 0, label: 'None' }] },
        {
            name: 'format', label: 'Format for date or number fields', hints: [
                { hint: 'Number Formatting Documentation', hintUrl: 'https://d3-wiki.readthedocs.io/zh_CN/master/Formatting/' },
                { hint: 'Date Formatting Documentation', hintUrl: 'https://day.js.org/docs/en/display/format' },
            ],
        },
        { label: 'Field is readonly', name: 'disabled', type: 'select', options: [{ value: true }, { value: false }] },
    ])

    validationSchema = getFields([
        { name: 'matches', label: 'Field must match RegExpression' },
        { name: 'type', label: 'Field must match a type', type: 'checkbox', options: [{ value: 'string' }, { value: 'number' }, { value: 'boolean' }, { value: '' }] },
        { name: 'required', label: 'Field is required', type: 'radio', options: [{ value: true }, { value: false }]},
    ])

    validationMessages = getFields([
        { name: 'required', label: 'Required Message', type: 'string' },
        { name: 'unique', type: 'radio', label: 'Value must be unique', options: [{ value: true }, { value: false }] },
    ])

    validationHints = getFields([
        { name: 'hint', label: 'Hints', type: 'string' },
    ])

    visibilitySchema = getFields([
        { label: 'Show in Table', name: 'list', type: 'select', options: [{ value: true }, { value: false }] },
        { label: 'Show in Form', name: 'edit', type: 'select', options: [{ value: true }, { value: false }] },
        { label: 'Show in Filter', name: 'filter', type: 'select', options: [{ value: true }, { value: false }] },
    ])

    optionsSchema = getFields([
        { name: 'label' },
        { name: 'value' },
    ])

    calculationsSchema = getFields([
        {
            name: 'relevant',
            label: 'Relevant',
            type: 'text',
            hints: [
                { hint: 'Show or hide field based on this expression' },
            ],
        }, {
            name: 'calculation',
            label: 'Calculation',
            hints: [
                { hint: 'Calculate this field based on other fields' },
            ],
            type: 'text',
        }, {
            name: 'default',
            label: 'Default calculation',
            hints: [
                { hint: 'Calculate or enter a default value' },
            ],
            type: 'text',
        }, {
            name: 'constraint',
            label: 'Constraint validation calculation',
            hints: [
                { hint: 'Validate value satisfies a constraint' },
            ],
            type: 'text',
        },
        {
            name: 'constraintError',
            label: 'Constraint validation message',
            type: 'text',
        },
        {
            name: 'repeatCount',
            label: 'Maximum number of repeated groups allowed',
            type: 'text',
        },
    ])

    attached() {
        this.field = getFields([this.field])[0];
    }

    addOption() {
        this.field.options = [
            ...this.field.options,
            {} as Option,
        ];
    }

    removeOption(index: number) {
        this.field.options.splice(index, 1);
    }

    updateCalculation(event) {
        const fieldName = event.target.name;
        const value = event.target.value;
        let result = parse(value, this.field.name);

        if (
            fieldName === 'default' &&
            result.ast.type === 'string' &&
            (result.ast.value as string)?.startsWith('Error')) {
            log.warn('Error parsing default value, retrying with quotes', result.ast.value);
            // assume the value needs quotes
            result = parse(`"${value}"`, this.field.name);
        }

        this.field[`${fieldName}Ast`] = result.ast;
        if (fieldName !== 'default') {
            this.field.dependencies = Array.from(new Set([
                ...result.dependencies,
                ...(this.field.dependencies || []),
            ]));
        }
        log.info('parse result', fieldName, result);

    }
}
