import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { bindable } from 'aurelia-framework';

export class FieldEditList {
   @bindable fields: Field[] = [];
   @bindable id: string;
   addField() {
       this.fields = [
           ...this.fields,
            {} as Field,
       ];
   }

   removeField(index: number){
       this.fields.splice(index, 1);
   }
}
