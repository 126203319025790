import { AlertService } from './../../../services/util/Alert';
import { ValidationControllerFactory, ValidationController } from 'aurelia-validation';
import { DialogController } from 'aurelia-dialog';
import { inject } from 'aurelia-dependency-injection';
import { ValidatedSecret } from '../../../types/Secret';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';

@inject(
    DatafiProAPI,
    DialogController,
    ValidationControllerFactory,
    AlertService,
)
export class ProgramAuthEdit {
    message: string;
    validationController: ValidationController;
    row: ValidatedSecret;

    constructor(
        private api: DatafiProAPI,
        private dialog: DialogController,
        private validationFactory: ValidationControllerFactory,
        private alerts: AlertService,
    ) { }

    activate(model?: ValidatedSecret) {
        this.row = new ValidatedSecret(model);
        this.validationController = this.validationFactory.createForCurrentScope();
    }

    submit() {
        return this.validationController.validate()
            .then((result) => {
                if (!result.valid) {
                    throw new Error('Form is not valid');
                }

                return this.api.secrets.create(this.row);
            })
            .then((result) => {
                this.alerts.create({
                    label: 'Credential saved',
                    level: 'success',
                    dismissable: true,
                });
                Object.assign(this.row, result);
                this.dialog.ok(this.row);
            })
            .catch((e) => this.alerts.create({
                label: e.message,
                level: 'error',
                dismissable: true,
            }));
    }
}
