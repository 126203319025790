import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { IActionParams } from './IActionParams';

export function getSaveFile(scope: IActionParams){
    return getFields([
        {
            name: 'name',
            label: 'File Name (custom name from data as "%metadata.<path>%")',
        },
    ]);
}
