import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { IDashboardTableConfig } from '../../types/Dashboards';

@autoinject
export class TableGadgetEditor {
    @bindable value: IDashboardTableConfig;
    store: SimpleStore | void;

    constructor(
        private stores: StoreManager,
        private ea: EventAggregator,
    ) { }

    activate(model: IDashboardTableConfig) {
        this.value = model;
    }

    dispatchEvent() {
        this.ea.publish('edit-item', this.value);
    }

    refresh() {
        this.store = this.stores.getStore(this.value.storeId);
        this.value.fields = this.store ? this.store.fields : [];
    }

}
