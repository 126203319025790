import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DefaultActionData, ProgramSurvey, Project } from '@wsb_dev/datafi-shared/lib/types';
import { ActionService } from '../../../services/actions/ActionService';
import { AlertService } from '../../../services/util/Alert';
import { ActionMessageData } from '@wsb_dev/datafi-shared/lib/types/ActionMessageData';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ProjectBreadcrumbsService } from '../../../services/assets/ProjectBreadcrumbs';

@autoinject

export class SurveyTypeList {
    @bindable project: Project
    @bindable projectId: number;
    surveys: ProgramSurvey[]
    inactiveSurveys: boolean;

    constructor(
        private program: ActiveProgram,
        private router: Router,
        private actionService: ActionService,
        private alerts: AlertService,
        private api: DatafiProAPI,
        private breadcrumbs: ProjectBreadcrumbsService,
    ) { }

    projectChanged(project: Project){
        this.surveys = project ?
            this.program.surveys.filter((survey) => survey.groupByArea) :
            this.program.surveys;
        this.inactiveSurveys = this.surveys.some((survey) => !survey.active);
    }

    createAction(action: DefaultActionData, survey: ProgramSurvey) {
        return this.actionService.create({
            ...action,
            target: this.project ? [this.project] : [],
            project: this.project,
            program: this.program,
            survey: survey,
        }).then((actionResult) => {
            if (actionResult.error) {
                this.alerts.create({
                    level: 'error',
                    label: actionResult.error.message,
                    dismissable: true,
                } as ActionMessageData);
            }
        });
    }
}
