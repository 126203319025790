import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { titleize } from '@wsb_dev/datafi-shared/lib/util/strings/titleize';
import { IActionParams } from './IActionParams';
import {flattenFields} from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';

export function getGenerateCSV(scope: IActionParams): Field[] {
    const flatFields = flattenFields( scope.survey.surveySchema, null, {arrays: true, preserveParent: true} );
    return getFields([
        {
            name: 'rowsField', label: 'Field to get rows from (like a nested repeat)', type: 'select',
            options: flatFields
                .filter((f) => f.type === 'array')
                .map((f) => ({ label: f.label, value: f.path })),
        },
        {
            name: 'fields',
            label: 'Select all the fields to include',
            type: 'checkbox',
            options: flatFields

                // convert them to option types  if necessary
                .map((f) => {
                    return  {
                        label: f.parent ?
                            `${titleize(f.parent.type)} ${f.parent.label || ''}: ${f.label}` :
                            f.label,
                        value: {
                            // computed from earlier path step
                            name: f.path,
                            label: f.label || f.name,
                        },
                    };
                })
                // search for existing objects in the config - if they are found use that instead
                // to enable pre-checking the correct values
                .map((f) => {
                    const existing = scope.action.fields?.find((existing) => existing.name === f.value.name);
                    if(existing){
                        return {label: f.label, value: existing};
                    }
                    return f;

                }),
        },
    ]);
}
