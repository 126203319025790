import { Field, Option } from '@wsb_dev/datafi-shared/lib/types';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { IActionParams } from './IActionParams';

export function getGenerateKoboURL(scope: IActionParams): Field[] {
    let fields: Field[];
    if(scope.survey.survey_type === 'dfp-form'){
        const metadata = scope.survey.surveySchema.find((f) => f.name === 'metadata');
        fields = metadata.fields;
    } else {
        fields = scope.survey.surveySchema;
    }
    const surveyFieldsSelectOptions: Option[] = fields.map((f) => ({value: f.name, label: f.label}));
    return getFields([
        {
            type: 'select',
            name: 'urlType',
            label: 'Type of URL',
            options: [
                // https://wsb-static-apps.s3.amazonaws.com/apps/datafi-types/enums/SurveyDeploymentURLType.html
                {value: 'offline_url', label: 'Online-Offline (multiple submissions)'},
                {value: 'url', label: 'Online only (multiple submissions)'},
                {value: 'single_url', label: 'Online only (single submission)'},
                {value: 'single_once_url', label: 'Online only (once per respondant)'},
                {value: 'preview_url', label: 'Preview only'},
                // {value: 'iframe_url', label: 'Iframe friendly URL'},
                // {value: 'single_iframe_url'},
            ],
        },
        {
            type: 'number',
            name: 'surveyId',
            label: 'Survey ID',
        },
        {
            label: 'Open form in new tab',
            name: 'tab',
            type: 'radio',
            options: [{value: 1, label: 'Yes'}, {value: 0, label: 'No'}],
        },
        {
            label: 'Open form in current tab',
            name: 'iframe',
            type: 'radio',
            options: [{value: 1, label: 'Yes'}, {value: 0, label: 'No'}],
        },
        {
            name: 'targetFields',
            label: 'Fields to pass to survey',
            type: 'object',
            fields: [
                {
                    name: 'id',
                    label: 'Project ID',
                    type: 'select',
                    options: surveyFieldsSelectOptions,
                },
                {
                    name: 'status',
                    label: 'Project Status',
                    type: 'select',
                    options: surveyFieldsSelectOptions,
                },
                {
                    name: 'projectname',
                    label: 'Project Name',
                    type: 'select',
                    options: surveyFieldsSelectOptions,
                },
            ].concat(scope.program.projectSchema.map((f) => {
                return {
                    name: `metadata.${f.name}`,
                    label: `Project Metadata: ${f.label}`,
                    type: 'select',
                    options: surveyFieldsSelectOptions,
                };
            })) as Field[],
        },
    ]);
}
