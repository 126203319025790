import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-dependency-injection';
import { LogManager, bindable } from 'aurelia-framework';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { ValidatedProject } from '../../../types/Project';
import { AlertService } from '../../../services/util/Alert';
import { Field } from '@wsb_dev/datafi-shared/lib/types/Field';
import { Paginated } from '@feathersjs/feathers';
import set from 'lodash.set';
import has from 'lodash.has';

export interface ISurveyEditParams {
    rows: Record<string, any>;
    fields: Field[]
}

export interface IMapToSurveyField {
    surveyField: Field;
    projectField: string;
    projectValue: string
}

const log = LogManager.getLogger('dfp:surveys');
@autoinject
export class SurveyEdit {
    rows: Record<string, any>;
    mappedField: IMapToSurveyField[] = [];

    currentProject: ValidatedProject;
    newProject: ValidatedProject;

    confirmation: boolean;

    @bindable projectQuery: string;
    @bindable fields: Field[]
    filteredFields: Field[]

    constructor(
        private dialog: DialogController,
        private api: DatafiProAPI,
        private program: ActiveProgram,
        private alerts: AlertService,
    ) { }

    activate(selection: ISurveyEditParams) {
        this.rows = selection.rows;
        this.fields = selection.fields;

        this.filteredFields = selection.fields.filter((field) => {
            const pathsToRemove = [
                'metadata.survey_id',
                'metadata.project_id',
                'metadata.username',
                'metadata.current_location',
                'metadata.username',
                'metadata.userId',
                'metadata.email',
                'metadata.program_id',
            ];
            return field.path.includes('metadata.') // only include fields with metadata paths
                && !pathsToRemove.includes(field.path) // remove specific paths
                && field.visibility.list; // only show fields that are visible in table
        });

        this.api.projects.get(this.rows[0].project_id)
            .then((project) => {
                this.currentProject = project;
            });
    }

    getProjects = async (filter: string) => {

        if (!this.program.id) {
            log.warn('project-list: no program id');
            return;
        }

        const query = filter ? {
            $or: [
                { project_name: { $ilike: `%${filter.trim()}%` } },
                { metadata: { $ilike: `%${filter.trim()}%` } },
            ],
        } : { $or: undefined };

        return this.api.projects.find({
            query: {
                ...query,
                program_id: this.program.id,
                $limit: 20,
            },
        }).then((result: Paginated<ValidatedProject>) => {
            return result.data;
        }).catch((e) => {
            return new Error(`There was an issue loading results. ${e}`);
        });
    }

    mapToSurveyField(selection: IMapToSurveyField) {
        const isMapped = this.mappedField?.some((field) => field.projectField === selection.projectField);

        if (!selection.surveyField) { // undefined survey field
            this.mappedField = this.mappedField?.filter((field) => !(field.projectField === selection.projectField));
            return;
        }

        if (isMapped) { // if a project selection has already been made, replace prop value
            const field = this.mappedField.find((field) => field.projectField === selection.projectField);
            Object.assign(field, selection);
        } else { // If project field selection hasn't been made, add new property & value
            this.mappedField.push(selection);
        }
    }

    async submit() {
        if (this.confirmation && this.program.id) {
            return Promise.all(this.rows.map((row: Record<string, number>) => {

                this.updateRowEntry(row, this.newProject.id);

                return this.api.formSubmissions.patch(row.id, {
                    ...row,
                    program_id: this.program.id,
                    project_id: this.newProject.id,
                });

            })).then(() => {
                this.alerts.create({
                    label: this.rows.length > 1 ? `Surveys are switched to ${this.newProject.project_name}` : `Survey is switched to ${this.newProject.project_name}`,
                    level: 'success',
                    dismissable: true,
                });
                this.dialog.ok(this.rows);
            }).catch((e) => {
                log.warn(e);
                this.alerts.create({
                    label: e,
                    level: 'error',
                    dismissable: true,
                });
            });
        }
    }

    updateRowEntry(row: Record<string, number>, projectId: number) {

        set(row, 'metadata.project_id', projectId);
        this.mappedField.forEach((field) => {
            const pathExists = has(row, field.surveyField.path);
            if (pathExists) {
                set(row, field.surveyField.path, field.projectValue);
            }
        });
    }
}
